import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, SecurityContext, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { coerceBoolean } from '@util/functions/objects';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-html-deserializer',
  template: '',
  styles: [':host {display: contents;}']
})
export class HtmlDeserializerComponent {

  private readonly sanitizer = inject(DomSanitizer);
  private elementRef = inject(ElementRef);
  private renderer2 = inject(Renderer2);

  private _stringBehaviorSubject = new BehaviorSubject<string>(null);
  private _allowStyles: boolean;

  get string(): string {
    return this._stringBehaviorSubject.value;
  }

  @Input()
  set string(value: string) {
    this._stringBehaviorSubject.next(value);
    this.serialize();
  }

  get allowStyles(): boolean {
    return this._allowStyles;
  }

  @Input()
  set allowStyles(value: boolean) {
    this._allowStyles = coerceBoolean(value);
    this.serialize();
  }

  private serialize() {

    let securityContext = this.allowStyles ? SecurityContext.STYLE : SecurityContext.HTML;

    const str = this.sanitizer.sanitize(securityContext, this.string);

    const parser = new DOMParser();
    const type: DOMParserSupportedType = 'text/html';
    const newDom = parser.parseFromString(str, type);
    const newNodes = Array.from(newDom.body.childNodes);
    const host = (this.elementRef.nativeElement) as HTMLElement;

    // host.childNodes.forEach(oldNodes => {
    //   this.renderer2.removeChild(host, oldNodes, true);
    // });

    // const children = Array.from(host.children);

    // children.forEach(oldChild => {
    //   this.renderer2.removeChild(host, oldChild, true);
    // });

    host.innerHTML = ''; // NOSONAR

    newNodes.forEach(node => {
      this.renderer2.appendChild(host, node);
    });
  }

}
